:root {
  --maf-background-image: linear-gradient(to right, #121212, #171717);
  --maf-primary-blue: #004df9;
}

@font-face {
  font-family: 'Stretch Pro';
  src: url('../public/fonts/StretchPro-Basic.otf') format('otf'),
    url('../public/fonts/StretchPro-Basic.ttf') format('truetype'),
    url('../public/fonts/StretchPro.otf') format('otf'),
    url('../public/fonts/StretchPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}






.maf_nav_parent {
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  width: 100vw;
  z-index: 999999;
}

.maf_menu_background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  z-index: 99;
  background: linear-gradient(to right, #121212, #171717)
}

.maf_menu_toggle_button {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  z-index: 999;
  color: var(--maf-primary-blue);
}

.maf_menu_ul_parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10em !important;
  padding: 25px;
  position: absolute;
  z-index: 999;
}

.maf_menu_ul_parent li {
  list-style: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.maf_blog_img_fill {
  height: 360px;
  object-fit: fill;
}

body {
  font-family: 'Inter', sans-serif;
  width: 100%;
  float: left;
  margin: 0px;
  overflow-x: hidden;
  line-height: 1.5 !important;
  font-size: inherit !important;
  background-image: linear-gradient(to right, #121212, #171717);
}

.maf_clear_btn {
  border: none !important;
  background-color: transparent !important;
}

.maf_slider_item_parent {
  min-height: 530px;
  align-items: center;
}

.slick-list {
  min-height: 530px !important;
}

.offcanvas-backdrop {
  background-color: transparent !important;
  height: 0 !important;
  width: 0 !important;
}

.maf_wrap_div {
  min-width: 60px;
}


.maf_home_slide_1 {
  min-height: 85vh;
}

.offcanvas-top {
  background-image: linear-gradient(to right, #121212, #171717);
  border: none !important;
  height: 87vh !important;
  width: 100% !important;
  top: 13vh !important;
}

.maf_main {
  width: 100%;
  padding: 1rem 1.2rem !important;
}

.height_fit_con {
  height: fit-content;
}

/*for color*/
.maf_c_white {
  color: #FFFFFF !important;
}

.maf_c_gray {
  color: #FFFFFFA3 !important;
}

.maf_menu_icon_label {
  display: flex;
  flex-direction: column;
  width: 50px;
  cursor: pointer;
  transform: rotateY(180deg);
}

.maf_menu_icon_label_span {
  background: var(--maf-primary-blue);
  border-radius: 1px;
  height: 5px;
  margin: 5px 0;
  transition: .4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.maf_menu_icon_label_span:nth-of-type(1) {
  width: 80%;
}

.maf_menu_icon_label_span:nth-of-type(2) {
  width: 100%;
}

.maf_menu_icon_label_span:nth-of-type(3) {
  width: 50%;
}

.maf_menu_icon_label.active>.maf_menu_icon_label_span:nth-of-type(1) {
  transform-origin: bottom;
  transform: rotatez(45deg) translate(9px, 0px);
  width: 55%
}

.maf_menu_icon_label.active>.maf_menu_icon_label_span:nth-of-type(2) {
  transform-origin: top;
  transform: translate(3px, 2px) rotatez(-47deg);
  width: 103%
}

.maf_menu_icon_label.active>.maf_menu_icon_label_span:nth-of-type(3) {
  transform-origin: bottom;
  width: 50%;
  transform: translate(25px, -6px) rotatez(45deg);
}

/*for font family*/
.ff_inter {
  font-family: 'Inter', sans-serif !important;
}

.ff_Roboto {
  font-family: 'Roboto', sans-serif !important;
}

.ff_Stretch_Pro {
  font-family: 'Stretch Pro', sans-serif !important;
}

/*for paraghraph text*/
.maf_blue_p {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 130%;
  letter-spacing: 0.695em;
  color: #004DF9;
}

.maf_p {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 140%;
  color: #FFFFFFA3;
}

.maf_p_s {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 140%;
  color: #FFFFFFA3;
}

.maf_p_xs {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 140%;
  color: #FFFFFFA3;
}

.maf_p_xxs {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 140%;
  color: #FFFFFFA3;
}

.maf_p_lg {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 140%;
  color: #FFFFFFA3;
}

.maf_logo_header {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.08em;
  text-align: left;
  color: #FFFFFF;
  z-index: 99999999;
}

.maf_logo_footer {
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  font-size: 2.75rem;
  line-height: 130%;
  letter-spacing: -0.08em;
  color: #FFFFFF;
}

.maf_menu_h {
  color: #FFFFFFA3 !important;
}

.maf_menu_h:hover,
.maf_menu_h:active {
  color: #FEFEFE !important;
}

/*for heading text*/
.maf_h1 {
  font-family: 'Stretch Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 120%;
  color: #FEFEFE;
}

.maf_h2 {
  font-family: 'Stretch Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.25rem;
  line-height: 140%;
  color: #FEFEFE;
}

.maf_h3 {
  font-family: 'Stretch Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 140%;
  color: #FEFEFE;
}

.maf_h4 {
  font-family: 'Stretch Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.625rem;
  line-height: 140%;
  color: #FEFEFE;
}

.maf_h5 {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.375rem;
  line-height: 123.5%;
  color: #FEFEFE;
}

.maf_h6 {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 140%;
  color: #FEFEFE;
}

/*for line height 100% t0 200%*/
.maf_lh_100 {
  line-height: 100%
}

.maf_lh_105 {
  line-height: 105%
}

.maf_lh_110 {
  line-height: 110%
}

.maf_lh_115 {
  line-height: 115%
}

.maf_lh_120 {
  line-height: 120%
}

.maf_lh_125 {
  line-height: 125%
}

.maf_lh_130 {
  line-height: 130%
}

.maf_lh_135 {
  line-height: 135%
}

.maf_lh_140 {
  line-height: 150%
}

.maf_lh_145 {
  line-height: 145%
}

.maf_lh_150 {
  line-height: 150%
}

.maf_lh_155 {
  line-height: 155%
}

.maf_lh_160 {
  line-height: 160%
}

.maf_lh_165 {
  line-height: 165%
}

.maf_lh_170 {
  line-height: 150%
}

.maf_lh_175 {
  line-height: 175%
}

.maf_lh_180 {
  line-height: 180%
}

.maf_lh_185 {
  line-height: 185%
}

.maf_lh_190 {
  line-height: 190%
}

.maf_lh_195 {
  line-height: 195%
}

.maf_lh_200 {
  line-height: 200%
}

/*for font weight*/
.fw_100 {
  font-weight: 100 !important;
}

.fw_200 {
  font-weight: 200 !important;
}

.fw_300 {
  font-weight: 300 !important;
}

.fw_400 {
  font-weight: 400 !important;
}

.fw_500 {
  font-weight: 500 !important;
}

.fw_600 {
  font-weight: 600 !important;
}

.fw_700 {
  font-weight: 700 !important;
}

.fw_800 {
  font-weight: 800 !important;
}

.fw_900 {
  font-weight: 900 !important;
}

/*for gap in d-flex*/
.gap_0_5rm {
  gap: 0.5rem;
}

.gap_1rm {
  gap: 1rem;
}

.gap_2rm {
  gap: 2rem;
}

.gap_3rm {
  gap: 3rem;
}

.maf_header_buttons {
  gap: 1.75rem;
}

.maf_exit_icon {
  width: 20px;
  height: 20px;
  cursor: poInter;
}

.maf_min_with_icons {
  min-width: 40px;
}

.maf_menu_icon {
  width: 40px;
  height: 20px;
  cursor: poInter;
}

.maf_header {
  flex: 0 1 auto;
  z-index: 9999999;
}

.maf_content {
  flex: 1 1 auto;
}

a {
  text-decoration: none !important;
}

div {
  outline: unset !important;
}

.maf_next_slide_btn {
  z-index: 999;
  position: relative;
}

.slide-up-down {
  animation: slide-up-down 0.7s ease-in-out;
}

@keyframes slide-up-down {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0%);
  }
}

.slide-down-up {
  animation: slide-down-up 0.7s ease-in-out;
}

@keyframes slide-down-up {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}

.maf_footer {
  flex: 0 1 auto;
  margin-top: 0.5rem;
  position: sticky;
  bottom: 20px;
}

/*css for menu pag*/
.maf_menu_pages {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 1.25rem;
  width: max-content;
}

.maf_menu_pages:hover .maf_h2 {
  opacity: 1;
}

.maf_menu_pages:active .maf_h2 {
  opacity: 1;
}

.maf_menu_pages_bar {
  display: none;
  height: 3px;
  width: 163px;
  background: #004DF9 !important;
  color: #004DF9 !important;
}

.maf_menu_pages:hover .maf_menu_pages_bar {
  display: block;
}

.maf_menu_pages:active .maf_menu_pages_bar {
  display: block;
}

/*css for buttons*/
.maf_btn {
  width: fit-content;
  border: 1px solid #FEFEFE;
  border-radius: 0;
  background: transparent;
  padding: 0 1.375rem;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 150%;
  color: #FEFEFE;
  margin-top: 1rem;
  vertical-align: middle;
  align-items: center;
}

.maf_btn_play {
  width: fit-content;
  border: none;
  border-radius: 50%;
  background: transparent;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 158.5%;
  color: #FEFEFE;
  margin-top: 1rem;
  vertical-align: middle;
  align-items: center;
}

/*css for icons*/
.maf_our_services_icon {
  width: 35px;
  height: 35px;
}

.maf_star_icon {
  width: 80px;
  height: 16px;
}

.maf_play_icon {
  width: 65px;
  hanging-punctuation: 65px;
}

.maf_arrow_icon {
  margin-left: 0.9rem;
  border: none;
  padding: 0 !important;
}

.maf_services_icon {
  width: 260px;
  height: 200px;
}

/*css for main img*/
.maf_col_img {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.maf_col_img_parent {
  /* display: flex; */
  width: 100%;
  height: 432px;
  align-items: center;
}

.maf_img_child {
  width: 100%;
  filter: drop-shadow(0px 4px 56px rgba(0, 0, 0, 0.04));
  border-radius: 0px;
  object-fit: cover;
  max-height: 432px;
}

.maf_img {
  width: 100%;
  height: 230px;
  filter: drop-shadow(0px 4px 56px rgba(0, 0, 0, 0.04));
  border-radius: 0px;
}

/*img*/
.maf_img_portfolio {
  width: 100%;
  height: auto;
  border-radius: 12px;
}

.maf_id_img {
  width: 100%;
  height: auto;
}

.maf_work_img {
  width: 100%;
  max-height: 175px;
}

.maf_diskirz_img {
  width: 100%;
  max-height: 500px;
  border-radius: 12px;
}

.maf_blog_img {
  width: 100%;
  max-height: 500px;
}

/*css for d-grid*/
.maf_services_cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 0.75rem 0.75rem;
}

.maf_portfolio_cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 1rem 1rem;
}

.maf_blog_cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 1rem 1rem;
}

.maf_services_expect {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 1rem 1rem;
}

.maf_work_cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 1.2rem 1.2rem;
}

.no_text_dec {
  text-decoration: none;
}

.maf_services_page_star_icon {
  width: 15%;
}

/*css for cards*/
.maf_card_t {
  background: transparent;
  border: 1px solid transparent;
  box-shadow: none;
  border-radius: 12px;
}

.maf_services_page_icons {
  width: 10%;
}

#owl_carousel_services .item {
  width: 10%;
}

.maf_card,
.maf_card_img {
  height: auto;
  background: rgba(217, 217, 217, 0.01);
  border: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.43);
  border-radius: 4px;
}

.maf_card_img {
  width: 90%;
}

.maf_custom_class {
  max-height: 300px;
}

.maf_br_xt8p {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.maf_br_xt12p {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

/*css for border radius*/
.maf_br_4p {
  border-radius: 4px !important;
}

.maf_br_8p {
  border-radius: 8px !important;
}

.maf_br_12p {
  border-radius: 12px !important;
}

.maf_Testimonials {
  width: 100%;
  margin: 0;
  overflow-y: scroll;
  height: 100%;
}

.maf_Testimonials::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
  display: none;
}

.maf_Testimonials_main {
  height: 100%;
  max-height: 300px;
}

.maf_testimonials_id {
  height: 39px;
  width: 39px;
  overflow: hidden;
  border-radius: 50%;
}

.c-poiner {
  cursor: pointer;
}

.slick-slide {
  height: auto !important;
}

.slick-track {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}

.maf_Testimonials_img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  /* width: 39px;xx
  height: 39px;
  border-radius: 28px; */
}

.maf_services_icons {
  width: max-content;
  margin: auto;
}

/*css for links*/
.maf_a {
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 175.5%;
  color: rgba(255, 255, 255, 0.92);
  cursor: poInter;
}

.maf_a:hover,
maf_a:active {
  color: rgba(255, 255, 255, 0.92);
}

.maf_email {
  background: transparent !important;
  border: none !important;
  outline: none !important;
  color: #FFFFFF !important;
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  font-weight: 500 !important;
}

.maf_email:focus,
maf_email:active {
  background: transparent !important;
  border: none !important;
  outline: none !important;
  color: #FFFFFF !important;
}

/*for dividers*/
.maf_divider_v {
  display: none;
  height: 100%;
  width: 2px;
  background: #FFFFFF26;
  color: #FFFFFF26 !important;
}

.maf_menu_divider {
  display: block;
  height: 2px;
  width: 15rem;
  background: #FFFFFF26;
  color: #FFFFFF26 !important;
}

.maf_divider_h {
  height: 2px;
  width: 100%;
  background: #FFFFFF26;
  color: #FFFFFF26 !important;
}

/*carousel css*/
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  color: #FEFEFE !important;
}

.owl-theme .owl-nav [class*=owl-]:hover {
  background: transparent !important;
}

/* Small devices (landscape phones, 576px and up)*/
@media (max-width: 480px) {
  .maf_home_slide_1 {
    margin: 1.3rem 0;
  }

  .maf_Testimonials_main {
    max-height: unset;
  }

  .maf_slider_item_parent {
    min-height: unset;
  }
}

@media (min-width: 481px) and (max-width: 768px) {}

@media (min-width: 769px) and (max-width: 1024px) {}

@media (min-width: 1025px) and (max-width: 1200px) {}

@media (min-width: 1201px) {}


/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .maf_blue_p {
    letter-spacing: 1.5em;
  }

  .maf_logo_header {
    font-size: 2rem;
    line-height: 130%;
  }

  .maf_logo_footer {
    font-size: 3.75rem;
  }

  .maf_img {
    width: 100%;
    height: 270px;
  }

  .maf_work_img {
    max-height: 100px;
  }

  .maf_id_img {
    width: 100%;
    height: auto;
  }

  .maf_services_cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 0.875rem 0.875rem;
  }

  .maf_portfolio_cards {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 1rem 1rem;
  }

  .maf_blog_cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 1rem 1rem;
  }

  .maf_services_expect {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 0.875rem 0.875rem;
  }

  .maf_work_cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 1rem 1rem;
  }


  .maf_divider_v {
    display: block;
  }

  .gap_sm_1rem {
    gap: 1rem;
  }

  .gap_sm_2rem {
    gap: 2rem;
  }

  .maf_play_icon {
    width: 84px;
    hanging-punctuation: 84px;
  }

  .maf_services_icon {
    width: 300px;
    height: 260px;
  }


}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .maf_body {
    height: 100vh;
  }

  .maf_main {
    height: 100%;
    padding: 2rem 3rem !important;
  }

  .maf_blue_p {
    letter-spacing: 1.695em;
  }

  .maf_p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: #FFFFFFA3;
  }

  .maf_p_s {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    color: #FFFFFFA3;
  }

  .maf_p_xs {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 150%;
    color: #FFFFFFA3;
  }

  .maf_p_xxs {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 150%;
    color: #FFFFFFA3;
  }

  .maf_p_lg {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 150%;
    color: #FFFFFFA3;
  }

  .maf_logo_header {
    font-size: 2rem;
    line-height: 140%;
  }

  .maf_h1 {
    font-size: 2.5rem;
    line-height: 130%;
  }

  .maf_h2 {
    font-size: 2.25rem;
    line-height: 140%;
  }

  .maf_h3 {
    font-size: 1.75rem;
    line-height: 140%;
  }

  .maf_h4 {
    font-size: 1.625rem;
    line-height: 140%;
  }

  .maf_h5 {
    font-size: 1.375rem;
    line-height: 123.5%;
  }

  .maf_h6 {
    font-size: 1.125rem;
    line-height: 140%;
  }

  .maf_img {
    width: 100%;
    height: 370px;
  }

  .maf_work_img {
    max-height: 125px;
  }

  .maf_diskirz_img {
    max-height: 400px;
  }

  .maf_services_cards {
    grid-gap: 1.5rem 1.5rem;
  }

  .maf_blog_cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 1.5rem 1.5rem;
  }

  .maf_work_cards {
    grid-gap: 1.3rem 1.3rem;
  }


  .maf_header_buttons {
    gap: 5.75rem;
  }

  .maf_btn {
    padding: 0 2.375rem;
    font-size: 1rem;
    line-height: 200%;
    margin-top: 2.5rem;
  }

  .maf_exit_icon {
    width: 34px;
    height: 35px;
  }

  .maf_menu_icon {
    width: 52px;
    height: 24px;
  }

  .maf_arrow_icon {
    margin-left: 1.5rem;
  }

  .maf_services_icon {
    width: 436px;
    height: 305px;
  }

  .maf_Testimonials_main {
    height: 100%;
    max-height: 400px;
  }

}

/* X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .maf_body {
    height: 100vh;
  }

  .maf_main {
    height: 100%;
    padding: 2rem 3rem !important;
  }

  .maf_blue_p {
    letter-spacing: 1.695em;
  }

  .maf_img {
    width: 100%;
    height: 432px;
  }

  .maf_work_img {
    max-height: 150px;
  }

  .maf_diskirz_img {
    max-height: 400px;
  }

  .maf_services_cards {
    grid-gap: 1.875rem 1.875rem;
  }

  .maf_portfolio_cards {
    grid-gap: 1.5rem 1.5rem;
  }

  .maf_work_cards {
    grid-gap: 1.5rem 1.5rem;
  }

  .maf_header_buttons {
    gap: 5.75rem;
  }

  .maf_btn {
    padding: 0 2.375rem;
    font-size: 1rem;
    line-height: 198.5%;
    margin-top: 2.5rem;
  }

  .maf_exit_icon {
    width: 34px;
    height: 35px;
  }

  .maf_menu_icon {
    width: 52px;
    height: 24px;
  }

  .maf_arrow_icon {
    margin-left: 1.5rem;
  }

  .maf_Testimonials_main {
    height: 100%;
    max-height: 80%;
  }

}


/* XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) {

  .maf_work_cards {
    grid-gap: 1.875rem 1.875rem;
  }

  .maf_work_img {
    max-height: 185px;
  }

  .maf_diskirz_img {
    max-height: 500px;
  }
}

@media (min-width: 1500px) {

  .maf_work_img {
    max-height: 200px;
  }

}